import { useIsLoggedInQuery } from 'auth/hooks'
import { useLocale, useTranslations } from 'next-intl'
import { Button, ButtonIcon } from 'primitives/button'
import { CFAskLogo, CrossIcon } from 'primitives/icons'

import { MenuIcon } from '@ask-cf/assets/icons'
import { LoginButton } from '@ask-cf/components/login-button'
import { SignUpButton } from '@ask-cf/components/sign-up-button'
import { SlideInMenu } from '@ask-cf/components/slide-in-menu'
import { StyledLink } from '@ask-cf/components/styled-link'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { LanguageSelect } from './language-switcher'
import { Links } from './navigation-links'
import { ASK_CF_FF_LANGUAGE_SWITCHER } from '@ask-cf/config'

export function MobileMenu({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const locale = useLocale()
  // eslint-disable-next-line local/codegen-query-patterns
  const isUserLoggedIn = useIsLoggedInQuery()
  const t = useTranslations('ask_cf.components.mobile_menu')
  return (
    <SlideInMenu
      isOpen={isOpen}
      onClose={onClose}
      className="z-50 flex flex-col p-4 xl:hidden"
      backdropClassName="xl:hidden"
    >
      <div className={'flex flex-row items-center gap-1'}>
        <Button
          variant={'invisible'}
          onClick={onClose}
          className="h-auto p-0"
          aria-label={t('close_menu_aria_label')}
        >
          <MenuIcon className="text-text-tertiary" />
        </Button>
        <StyledLink
          href={ROUTES.HOME(locale)}
          className="grow"
          aria-label={t('home_link_aria_label')}
        >
          <CFAskLogo className="h-8 xl:h-11" />
        </StyledLink>
        <ButtonIcon
          onClick={onClose}
          className="z-20 h-auto"
          aria-label={t('close_menu_aria_label')}
        >
          <CrossIcon className="text-text-tertiary" />
        </ButtonIcon>
      </div>
      <ul id="nav-links" className="flex grow flex-col">
        <Links openQuestionDialogCallback={onClose} />
      </ul>
      <div className="flex flex-col items-start gap-6">
        {!isUserLoggedIn.data ? (
          <>
            <SignUpButton />
            <LoginButton />
          </>
        ) : null}
        {ASK_CF_FF_LANGUAGE_SWITCHER ? <LanguageSelect /> : null}
      </div>
    </SlideInMenu>
  )
}
