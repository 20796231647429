'use client'

import * as React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { UserAuthModalProvider } from 'components/user-auth-dialog/user-auth-dialog-context'

import { queryClient } from '@ask-cf/utils/query-client'
import { QuestionDialogProvider } from '@ask-cf/contexts/question-dialog-context'
import { SocialShareDialogProvider } from '@ask-cf/contexts/social-share-dialog-context'
import { AnswerDialogProvider } from '@ask-cf/contexts/answer-dialog-context'
import { NotificationSettingsDialogProvider } from '@ask-cf/contexts/notification-settings-dialog-context'
import { ReportQuestionDialogProvider } from '@ask-cf/contexts/report-question-dialog-context'
import { NotifyLoginDialogProvider } from '@ask-cf/contexts/notify-login-dialog-context'
import { LocaleTracker } from '@ask-cf/components/locale-tracker'
import { FollowProvider } from '@ask-cf/contexts/follow-user-context'

export function Providers({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <QuestionDialogProvider>
        <SocialShareDialogProvider>
          <ReportQuestionDialogProvider>
            <NotificationSettingsDialogProvider>
              <NotifyLoginDialogProvider>
                <FollowProvider>
                  <UserAuthModalProvider>
                    <AnswerDialogProvider>{children}</AnswerDialogProvider>
                  </UserAuthModalProvider>
                </FollowProvider>
              </NotifyLoginDialogProvider>
            </NotificationSettingsDialogProvider>
          </ReportQuestionDialogProvider>
        </SocialShareDialogProvider>
      </QuestionDialogProvider>
      <LocaleTracker />
    </QueryClientProvider>
  )
}
