import { useLocale, useTranslations } from 'next-intl'
import { usePathname } from 'next/navigation'
import { tw } from 'utils/classnames'
import { useIsLoggedInQuery } from 'auth/hooks'

import { HomeIcon, InterestsIcon, LeaderboardIcon, QuestionBubbleIcon } from '@ask-cf/assets/icons'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { StyledLink } from '@ask-cf/components/styled-link'
import { StyledButton } from '@ask-cf/components/styled-button'
import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { useIsActiveRoute } from '@ask-cf/hooks/use-is-active-route'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { ASK_CF_FF_LEADERBOARD } from '@ask-cf/config'

export function Links({ openQuestionDialogCallback }: { openQuestionDialogCallback?: () => void }) {
  const locale = useLocale()
  const pathname = usePathname()
  const t = useTranslations('ask_cf.components.navbar')
  const isLoggedInQuery = useIsLoggedInQuery()
  const { openLoginDialog } = useNotifyLoginDialog()

  const isRootRoute = pathname === '/' || pathname === `/${locale}/`
  const isRoute = useIsActiveRoute()

  const { openDialog } = useQuestionDialog()

  const openAskQuestionDialog = () => {
    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      openLoginDialog(t('log_in_dialog_title'))
    } else {
      openDialog()
      openQuestionDialogCallback?.()
    }
  }

  return (
    <>
      <Link
        href={ROUTES.HOME(locale)}
        active={isRootRoute}
        label={t('home_link.label')}
        icon={<HomeIcon />}
      />
      <Link
        href={ROUTES.ALL_TOPICS('', locale)}
        active={isRoute(ROUTES.ALL_TOPICS('', locale))}
        label={t('topics_link.label')}
        icon={<InterestsIcon />}
      />
      <Button
        onClick={openAskQuestionDialog}
        label={t('ask_question.label')}
        icon={<QuestionBubbleIcon />}
        disabled={isLoggedInQuery.isLoading}
      />
      {ASK_CF_FF_LEADERBOARD ? (
        <Link
          href={ROUTES.LEADERBOARD(locale)}
          active={isRoute(ROUTES.LEADERBOARD(locale))}
          label={t('leaderboard_link.label')}
          icon={<LeaderboardIcon />}
          className="tablet:hidden"
        />
      ) : null}
    </>
  )
}

function Link({
  href,
  active,
  label,
  icon,
  className,
}: {
  href: string
  active: boolean
  label: string
  icon: React.ReactNode
  className?: string
}) {
  return (
    <li>
      <StyledLink
        href={href}
        active={active}
        className={tw('border-b border-b-gray-400 py-4 xl:border-b-0 xl:py-0', className)}
        aria-label={label}
      >
        {icon} <span className="text-md ml-1 leading-6">{label}</span>
      </StyledLink>
    </li>
  )
}

function Button({
  label,
  icon,
  onClick,
  disabled = false,
}: {
  label: string
  icon: React.ReactNode
  onClick: () => void
  disabled: boolean
}) {
  return (
    <li>
      <StyledButton
        noAnimation
        className="w-full justify-start border-b border-b-gray-400 py-4 xl:border-b-0 xl:py-0"
        variant="link"
        onClick={onClick}
        aria-label={label}
        disabled={disabled}
      >
        {icon} <span className="text-md ml-1 leading-6">{label}</span>
      </StyledButton>
    </li>
  )
}
